import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Grid from '@mui/material/Grid2';
import { useState, useEffect, useRef } from 'react';
import {useNavigate} from "react-router-dom";
import React from 'react';

const Home = () => {

    const handleScroll = (event, sectionId) => {
        event.preventDefault();
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: "smooth" });
    }

    const [open1, setOpen1] = useState(false)
    const handleOpen1 = () => {
        setOpen1(!open1)
    }

    const [open2, setOpen2] = useState(false)
    const handleOpen2 = () => {
        setOpen2(!open2)
    }
    
    const [open3, setOpen3] = useState(false)
    const handleOpen3 = () => {
        setOpen3(!open3)
    }

    const [open4, setOpen4] = useState(false)
    const handleOpen4 = () => {
        setOpen4(!open4)
    }


    const [isVisible, setVisible] = useState(false); // Estado para controlar a visibilidade
    const domRef = useRef(null); // Referência ao elemento DOM

    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
            setVisible(true); // Ativa a visibilidade ao entrar na viewport
            observer.unobserve(entry.target); // Desativa o observador após a primeira interação
            }
        });
        });

        const { current } = domRef;

        if (current) {
        observer.observe(current); // Começa a observar o elemento
        }

        return () => {
        if (current) {
            observer.unobserve(current); // Cleanup para evitar memory leaks
        }
        };
    }, []);
    
    return (
        <div className="container-pg">
            <div className="header-container" id='home'>
                <img className="logoTopo" src="./Images/sigma.png" alt='logo'/>
                <div className="header-menu">
                    <ul className="navbar">
                        <li><a href="#home" onClick={(e) => handleScroll(e, 'home')}>HOME</a></li>
                        <li><a href="#beneficios" onClick={(e) => handleScroll(e, 'beneficios')}>BENEFÍCIOS</a></li>
                        <li><a href="#funcionalidades" onClick={(e) => handleScroll(e, 'funcionalidades')}>FUNCIONALIDADES</a></li>
                        <li><a href="#portfolio" onClick={(e) => handleScroll(e, 'portfolio')}>PORTFÓLIO</a></li>
                    </ul>
                    <a href='https://wa.me/+5545991166770' style={{textDecoration:'none', color:'#000',}}><button className="header-btn">ORÇAMENTO</button></a>
                    
                </div>
            </div>
            <div className="container-banner">
                <div className="banner-bg">
                    <div className="content-banner">
                        <div className="line1-title">Tire seu projeto do papel!</div>
                        <hr className="line1"/>
                        <div className="banner-title"> Transformando Ideias em Soluções Digitais</div>
                        <div className="banner-subtitle"><strong>Sistemas</strong> personalizados, <strong>sites</strong> modernos e <strong>aplicativos</strong> inovadores.<br/>Tudo para levar sua empresa ao próximo nível.</div>
                        <div className="banner-btns">
                            <a href='https://wa.me/+5545991166770' style={{textDecoration:'none', color:'#000'}}>
                                <button className="banner-btn-left">Pedir Orçamento <WhatsAppIcon sx={{marginLeft:1, fontSize:32}}/></button>
                            </a>
                            <button className="banner-btn-right" onClick={(e) => handleScroll(e, 'portfolio')}>Veja Nosso Portfólio</button>
                        </div>
                        <img className="bannerimg" src="./Images/bannerimg.svg" alt='imagem-banner'/>
                        <img className="mask" src="./Images/mask.svg" alt='background-banner'/>
                    </div>
                </div>
            </div>
            <div className='bene-container' id='beneficios'>
                <div className="bene-content">
                    <div className='bene-bg'/>
                    <img src='./Images/phone.png' className='phone' alt='phone'/>
                    <img src='./Images/shadow.png' className='shadow' alt='phone-shadow'/>
                    <div className='bene-right'>
                        <div style={{maxWidth:320}}>
                            <div className="bene-title">Porque Escolher<br/>Nossa Empresa?</div>
                            <div className='bene-bg-title'/>
                        </div>
                        
                        <div className='bene-div' style={{marginBottom:25}}>
                            <div className='bene-line1'>
                                <img src='./Images/sigmaicon.svg' alt='logo-icon'/>
                                <div className='bene-title2'>Personalização Completa</div>
                            </div>
                            <div className="bene-line2">
                            Cada solução é feita sob medida para atender às necessidades específicas do
                            seu negócio.
                            </div>
                        </div>
                        <div className='bene-div' style={{marginBottom:25}}>
                            <div className='bene-line1'>
                                <img src='./Images/sigmaicon.svg' alt='logo-icon'/>
                                <div className='bene-title2'>Suporte Ágil</div>
                            </div>
                            <div className="bene-line2">
                            Suporte dedicado para garantir que tudo funcione perfeitamente, sempre que você precisar.
                            </div>
                        </div>
                        <div className='bene-div' style={{marginBottom:25}}>
                            <div className='bene-line1'>
                                <img src='./Images/sigmaicon.svg' alt='logo-icon'/>
                                <div className='bene-title2'>Experiência e Inovação</div>
                            </div>
                            <div className="bene-line2">
                            Mais de 4 anos de experiência entregando sistemas e sites com as tecnologias mais modernas.
                            </div>
                        </div>
                        <div className='bene-div'>
                            <div className='bene-line1'>
                                <img src='./Images/sigmaicon.svg' alt='logo-icon'/>
                                <div className='bene-title2'>Foco em Resultados</div>
                            </div>
                            <div className="bene-line2">
                            Nosso objetivo é desenvolver soluções que impulsionem seus resultados, trazendo
                            mais eficiência e crescimento.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`function-container ${isVisible ? 'is-visible' : ''}`} ref={domRef} id='funcionalidades'>
                <div className="function-content">
                    <div className='func-title'>O Que Podemos Fazer por Você?</div>
                    <div className='func-bg-title'/>
                    
                    <Grid columns={{ xs: 4, sm: 8, md: 12 }} container spacing={2} justifyContent="center" marginTop='70px'>
                        <Grid item>
                            <div className="func-blk">
                                <img src='./Images/sistem.svg' className='func-icon' alt='func-icon'></img>
                                <div className='blk-title'>Desenvolvimento<br/>de Sistemas</div>
                                <hr className='blk-line'/>
                                <div className="blk-text">Sistemas robustos para gestão, automação e otimização de processos, garantindo mais eficiência para sua empresa.</div>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="func-blk">
                                <img src='./Images/website.svg' className='func-icon' alt='func-icon'></img>
                                <div className='blk-title'>Criação de<br/>Sites</div>
                                <hr className='blk-line'/>
                                <div className="blk-text">Sites responsivos, rápidos e otimização para SEO, garantindo a melhor experiência para seus visitantes em qualquer dispositivo.</div>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="func-blk">
                                <img src='./Images/phone.svg' className='func-icon' alt='func-icon'></img>
                                <div className='blk-title'>Desenvolvimento<br/>de Aplicativos</div>
                                <hr className='blk-line'/>
                                <div className="blk-text">Aplicativos nativos e híbridos, feitos para engajar seus clientes e melhorar a presença digital do seu negócio.</div>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="func-blk">
                                <img src='./Images/cloud.svg' className='func-icon' alt='func-icon'></img>
                                <div className='blk-title'>Integração de<br/>APIs</div>
                                <hr className='blk-line'/>
                                <div className="blk-text">Integração de sistemas e plataformas para centralizar suas operações e facilitar o gerenciamento do seu negócio.</div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="start-container">
                <div className="start-content">
                    <div className='start-title'>Vamos começar<br/>um projeto juntos?</div>
                    <div className='start-right'>
                        <div className='start-text'>
                        <strong>Nós acreditamos no potencial da sua empresa e oferecemos as ferramentas certas para você!</strong><br/>
                        Uma empresa completa, para um cliente mais que especial.
                        </div>
                        <a href='https://wa.me/+5545991166770' style={{textDecoration:'none', color:'#000', marginTop:30}}>
                            <button className="start-btn">Fale Conosco<WhatsAppIcon sx={{marginLeft:1, fontSize:32}}/></button>
                        </a>
                    </div>
                </div>
            </div>
            <div className="port-container" id='portfolio'>
                <div className="port-content">
                    <div className='port-title'>Nosso Trabalho!</div>
                    <div className='port-bg-title'/>
                    <Grid columns={{ xs: 4, sm: 8, md: 12 }} container spacing={2} justifyContent="center" marginTop='50px'>
                        <Grid item>
                            <a href='https://tattoo.sigmasystems.com.br'><img className="port" src='./Images/portfolio-tattoo.png' alt='portfolio-tattoo'/></a>
                        </Grid>
                        <Grid item>
                            <a href='https://tattoo.sigmasystems.com.br'><img className="port" src='./Images/portfolio-tattoo.png' alt='portfolio-tattoo'/></a>
                        </Grid>
                        <Grid item>
                            <a href='https://tattoo.sigmasystems.com.br'><img className="port" src='./Images/portfolio-tattoo.png' alt='portfolio-tattoo'/></a>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="faq-container">
                <div className="faq-content">
                    <div className="faq-left">
                        <div className="faq-title">Perguntas<br/>Frequentes!</div>
                        <hr className='faq-bar'/>
                        <div className='faq-text'>Selecionamos as perguntas mais frequentes feita por nossos clientes.<br/><strong>Confira e tire as suas também!</strong></div>
                        <a href='https://wa.me/+5545991166770' style={{textDecoration:'none', color:'#000', width:200, height:57}}>
                            <button className="faq-btn">Pedir Orçamento <WhatsAppIcon sx={{marginLeft:1, fontSize:32}}/></button>
                        </a>
                    </div>
                    <div className="faq-right">
                        <div className={`ask ${open1 ? 'open':''}`}>
                            <div className={`faq-ask ${open1 ? 'open':''}`} onClick={handleOpen1}>
                                <div className='faq-ask-title'>Quanto custa fazer meu projeto?</div>
                                <img src='./Images/sigmaicon.svg' className={`faq-icon ${open1 ? 'open':''}`} alt='logo-icon'/>
                            </div> 
                            <div className={`faq-answer ${open1 ? 'open':''}`}>
                                <div className='faq-answer-text'>O valor pode variar dependendo das funcionalidades e complexidade do projeto, como pagamentos, integrações, entre outros! Sites institucionais costumam ser mais baratos.</div>
                            </div>
                        </div>
                        <div className={`ask ${open2 ? 'open':''}`}>
                            <div className={`faq-ask ${open2 ? 'open':''}`} onClick={handleOpen2}>
                                <div className='faq-ask-title'>Em quanto tempo meu projeto fica pronto?</div>
                                <img src='./Images/sigmaicon.svg' className={`faq-icon ${open2 ? 'open':''}`} alt='logo-icon'/>
                            </div> 
                            <div className={`faq-answer ${open2 ? 'open':''}`}>
                                <div className='faq-answer-text'>O prazo depende muito da complexidade do projeto, funcionalidades e se o projeto será um Sistema, um Website ou até mesmo um App.</div>
                            </div>
                        </div>
                        <div className={`ask ${open3 ? 'open':''}`}>
                            <div className={`faq-ask ${open3 ? 'open':''}`} onClick={handleOpen3}>
                                <div className='faq-ask-title'>Vocês oferecem hospedagem?</div>
                                <img src='./Images/sigmaicon.svg' className={`faq-icon ${open3 ? 'open':''}`} alt='logo-icon'/>
                            </div> 
                            <div className={`faq-answer ${open3 ? 'open':''}`}>
                                <div className='faq-answer-text'><strong>Sim!</strong> Cuidamos de tudo para que você não tenha que se preocupar com nada, seu projeto estará <strong>24h</strong> por dia com o máximo de performance, velocidade e segurança.</div>
                            </div>
                        </div>
                        <div className={`ask ${open4 ? 'open':''}`}>
                            <div className={`faq-ask ${open4 ? 'open':''}`} onClick={handleOpen4}>
                                <div className='faq-ask-title'>Quais garantias tenho contratando a Sigma?</div>
                                <img src='./Images/sigmaicon.svg' className={`faq-icon ${open4 ? 'open':''}`} alt='logo-icon'/>
                            </div> 
                            <div className={`faq-answer ${open4 ? 'open':''}`}>
                                <div className='faq-answer-text'>Você terá um contrato digital assinado por ambas partes com todo o cronograma e validedade jurídica.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-container">
                <div className="footer-content">
                    <div className="footer-left">
                        <img src='./Images/sigma.png' className='footer-logo' alt='logo'></img>
                        <div className='follow-us'>Siga-nos nas redes sociais!</div>
                        <div className='social-media'>
                            <a href='https://instagram.com/sigmasystemsbr'><button className='rs-icon'><InstagramIcon sx={{fontSize:32, color:'#FFF'}}/></button></a>
                            <a href='https://facebook.com' style={{marginInline:10}}><button className="rs-icon"><FacebookRoundedIcon sx={{fontSize:32, color:'#FFF'}}/></button></a>
                            <a href='https://wa.me/+5545991166770'><button className="rs-icon"><WhatsAppIcon sx={{fontSize:32, color:'#FFF'}}/></button></a>
                        </div>
                    </div>
                    <div className="footer-right">
                        <div className="footer-contato">
                            <div className="footer-title">Contato</div>
                            <div className='footer-text'>WhatsApp<br/><strong>(45) 99116-6770</strong></div>
                        </div>
                        <div className="footer-adress">
                            <div className="footer-title">Endereço</div>
                            <div className='footer-text'>Em breve...</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-down">© 2024 - Sigma System - Todos os direitos reservados</div>
        </div>
    )
}
export default Home;